import { AccountInfo } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { Lock } from "@mui/icons-material";
import { Autocomplete, Chip, FormControl, FormLabel, Skeleton, Tooltip, createFilterOptions, useTheme } from "@mui/joy";
import { useGlobalContext } from "../../Context";
import { Core } from '../../utilities/core';
import { Labels } from "./constructs";

interface AutocompleteProps {
    name: string;
    label: Labels;
    value: string;
    options: string[];
    core: Core;
    isLocked: boolean;
    handleKeyUp?: (event: any) => void;
    labelRenderOption?: (props: any, option: any) => JSX.Element;
    isLoading?: boolean;
    groupBy?: (option: string) => string
}


export default function AutocompleteComponent({ name, label, value, options, labelRenderOption, handleKeyUp, isLoading = false, groupBy, isLocked }: AutocompleteProps) {
    const { profile } = useGlobalContext();
    const theme = useTheme();

    const filterManager = createFilterOptions({
        matchFrom: 'any',
        ignoreCase: true,
        trim: true,
        stringify: (option: AccountInfo) => {
            return option.displayName + option.userPrincipalName;
        }
    });

    const filterSite = createFilterOptions({
        matchFrom: "any",
        ignoreCase: true,
        trim: true,
        stringify: (option: any) => {
            return option.displayName + option.officeCode;
        }
    });

    const filterGeneric = createFilterOptions({
        matchFrom: "any",
        ignoreCase: true,
        trim: true,
        stringify: (option: any) => {
            return option.label;
        }
    });

    const getFilterMethod = (label: string) => {
        switch (label) {
            case "Manager":
                return filterManager;
            case "Location":
                return filterSite;
            default:
                return filterGeneric;
        }
    }

    return (
        <Tooltip title="Locked" placement="right" size="sm" arrow sx={{
            display: !isLocked ? "none" : undefined
        }}>
            <FormControl sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }} >
                <FormLabel sx={{ display: { sm: 'none', xs: 'flex' } }}>{label}</FormLabel>
                <Autocomplete
                    required
                    name={name}
                    startDecorator={<Chip sx={{ display: { sm: 'flex', xs: 'none' } }}>{label}</Chip>}
                    options={options}
                    sx={{ flex: 1, minWidth: { xs: '100%', sm: '49%' } }}
                    onKeyUp={handleKeyUp ? (event) => handleKeyUp(event) : undefined}
                    selectOnFocus
                    handleHomeEndKeys
                    isOptionEqualToValue={() => {
                        return true;
                    }}
                    defaultValue={value}
                    filterOptions={getFilterMethod(label)}
                    renderOption={labelRenderOption && ((props, option) => labelRenderOption(props, option))}
                    loading={isLoading ? isLoading : undefined}
                    {...(label === 'Manager' && { onChange: handleKeyUp })}
                    groupBy={groupBy}
                    disabled={isLocked}
                    endDecorator={isLocked ? <Lock sx={theme.palette.mode === "dark" ? { color: "#CFD8DC" } : { color: "#636b74" }} /> : undefined}
                    freeSolo={isLocked ? true : false}
                />
                <Skeleton loading={profile ? false : true} animation="wave"></Skeleton>
            </FormControl>
        </Tooltip>
    );
}
