import { NewUserProps } from '@efwwit/integration-hub-sdk/dist/nodejs/types/identity';
import { MoreVert, NearbyError, PersonAdd } from "@mui/icons-material";
import { Box, Button, IconButton, Tab, TabList, TabPanel, Tabs, Theme, Typography, chipClasses, tabClasses } from "@mui/joy";
import { useEffect, useState } from "react";
import { useEffectOnce, useHash } from "react-use";
import { useGlobalContext } from "../../Context";
import { validateForm } from "../AccountProfile";
import FormSection from "../AccountProfile/FormSection";
import InvalidFormModal from "../AccountProfile/InvalidFormModal";
import { FormSubmission } from "../AccountProfile/constructs";
import { FormValues, getFormLabel } from "../AccountProfile/utils";
import Header from "../Home/Header";
import UnauthorisedCreate from "../Unauthorised";
import { toggleSidebar } from "../utils";
import { CreateConfirmationModal } from "./ConfirmationModal";
import { baseCreateUser } from "./constructs";

const tabTheme = (theme: Theme) => {
    return {
        '--Chip-minHeight': '20px',
        '--ListItem-minHeight': '48px',
        top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
        zIndex: 10,
        width: '100%',
        overflow: 'auto hidden',
        alignSelf: 'flex-start',
        scrollSnapType: 'inline',
        '&::after': {
            pointerEvents: 'none',
            display: { xs: 'block', sm: 'none' },
            content: '""',
            position: 'sticky',
            top: 0,
            width: 40,
            flex: 'none',
            zIndex: 1,
            right: 0,
            borderBottom: '1px solid transparent',
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        [`& .${tabClasses.root}`]: {
            '--focus-outline-offset': '-2px',
            scrollSnapAlign: 'start',
            bgcolor: 'transparent',
            flex: 'none',
            '&:hover': {
                bgcolor: 'transparent',
            },
            [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                bgcolor: 'transparent',
                [`& .${chipClasses.root}`]: theme.variants.solid.primary,
            },
        },
    }
}

export default function CreateUser() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hash, setHash] = useHash();
    const { core, rootUser } = useGlobalContext();

    const [profileContent, setProfileContent] = useState<any>(null);
    const [formValues, setFormValues] = useState();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [isValid, setIsValid] = useState<boolean>(false);

    //#region UseEffects
    useEffectOnce(() => {
        setHash("#/create/people");
    });

    useEffect(() => {
        if (rootUser) {
            setProfileContent({
                personal: [
                    {
                        key: "Account Type",
                        label: "Account Type",
                        name: "accountType",
                        value: "",
                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "Primary Domain",
                        label: "Primary Domain",
                        name: "primaryDomain",
                        value: "ef.com",
                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "First Name",
                        label: "First Name",
                        name: "givenName",
                        value: " ",
                        isLocked: false,
                        type: "free"
                    },

                    {
                        key: "Last Name",
                        label: "Last Name",
                        name: "surname",
                        value: " ",
                        isLocked: false,
                        type: "free"
                    },
                ],
                contact: [
                    {
                        key: "Personal Phone",
                        label: "Personal Phone",
                        name: "personalPhone",
                        value: " ",
                        type: "free",
                        isLocked: false,
                    },
                    {
                        key: "Personal Email",
                        label: "Personal Email",
                        name: "personalEmail",
                        value: " ",
                        isLocked: false,
                        type: "free"
                    },
                ],
                work: [{
                    key: "Job Title",
                    label: "Job Title",
                    name: "jobTitle",
                    value: " ",
                    isLocked: false,
                    type: "free"
                },
                {
                    key: "Department",
                    label: "Department",
                    name: "department",
                    value: rootUser?.department,
                    isLocked: false,
                    type: "list"
                },
                {
                    key: "Manager",
                    label: "Manager",
                    name: "managerUpn",
                    value: rootUser?.userPrincipalName,
                    isLocked: false,
                    type: "list"
                },
                {
                    key: "Location",
                    label: "Location",
                    name: "officeLocation",
                    value: rootUser?.officeLocation,
                    isLocked: false,
                    type: "list"
                },
                ],
                finance: [
                    {
                        key: "Product",
                        label: "Product",
                        name: "product",
                        value: rootUser?.Finance?.product || " ",
                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "Product Market",
                        label: "Product Market",
                        name: "productMarket",
                        value: rootUser?.Finance?.productMarket || " ",

                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "Company",
                        label: "Company",
                        name: "company",
                        value: rootUser?.Finance?.company || " ",

                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "Sales Market",
                        label: "Sales Market",
                        name: "salesMarket",
                        value: rootUser?.Finance?.salesMarket || " ",
                        isLocked: false,
                        type: "list"
                    },
                    {
                        key: "Cost Center",
                        label: "Cost Center",
                        name: "costCenter",
                        value: rootUser?.Finance?.costCenter || " ",
                        isLocked: false,
                        type: "list"
                    }
                ],
            })
        }
    }, [rootUser])

    useEffect(() => {
        if (core) {
            if (
                core &&
                (
                    !rootUser?.RBAC?.iaaRole.includes("globalAdmin") &&
                    !rootUser?.RBAC?.iaaRole.includes("createStaff") &&
                    !rootUser?.RBAC?.iaaRole.includes("createConsultant") &&
                    !rootUser?.RBAC?.iaaRole.includes("createTD") &&
                    !rootUser?.RBAC?.iaaRole.includes("userAdmin") &&
                    !rootUser?.RBAC?.iaaRole.includes("createTeacher")
                )
            ) {
                setHash("#/create");
            }
        }
    }, [core]);


    useEffect(() => {
        setOpenModal(formSubmitted);
    }, [formSubmitted]);

    const setModal = (modalStatus: boolean) => {
        setOpenModal(modalStatus);
    }
    //#endregion

    const returnModal = () => {
        if (isValid) {
            return (
                <CreateConfirmationModal isOpen={openModal} setIsOpen={setModal} createPayload={formValues as unknown as NewUserProps} />)
        } else {
            return <InvalidFormModal setIsOpen={setModal} isOpen={openModal} message={`The following fields have invalid values: ${errors?.map((val) => `${getFormLabel(val as FormValues) || val}`).join(", ")}.`} />
        }
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setOpenModal(false);
        event.preventDefault();
        setFormSubmitted(true);

        if (core) {
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries()) as unknown as FormSubmission;

            if ((formJson.officeLocation.startsWith("CA") || formJson.officeLocation.startsWith("US")) && ((formJson.accountType !== "EXTERNAL CONSULTANT" && formJson.accountType !== "TOUR DIRECTOR"))) {
                setErrors([
                    "If you are hiring for the US and Canada, Oracle will now create and manage staff accounts. If you require assistance please reach out to your HR team."
                ]);
                setOpenModal(true);
            } else {
                const formErrors = validateForm(formJson);
                setErrors(formErrors);

                setIsValid(formErrors.length === 0);
                if (formErrors.length === 0) {
                    const dateNow = new Date();
                    const dateExpires = new Date(new Date().setMonth(dateNow.getMonth() + 6));
                    formJson.contractEnd = dateExpires.toISOString();
                    formJson.contractStart = dateNow.toISOString();
                    setFormValues(formJson as any);
                    setOpenModal(true);
                } else {
                    setOpenModal(true);
                }
            }
        }
    }

    return (
        (
            rootUser?.RBAC?.iaaRole.includes("globalAdmin") ||
            rootUser?.RBAC?.iaaRole.includes("createStaff") ||
            rootUser?.RBAC?.iaaRole.includes("createConsultant") ||
            rootUser?.RBAC?.iaaRole.includes("createTD") ||
            rootUser?.RBAC?.iaaRole.includes("userAdmin") ||
            rootUser?.RBAC?.iaaRole.includes("createTeacher")
        ) || !core ?
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <form
                    onSubmit={(event) => handleFormSubmit(event)}>

                    <Header icon={<PersonAdd />} title="Create a new Account" />
                    <IconButton
                        sx={{
                            display: {
                                xs: "block",
                                sm: "block",
                                md: "none",
                                lg: "none",
                            },
                            position: "absolute",
                            top: 0,
                            right: 0,
                            pt: 0.5,
                            m: 2,
                            zIndex: 5
                        }}
                        onClick={() => toggleSidebar()}
                        color="neutral"
                    >
                        <MoreVert sx={{ color: "white" }} />
                    </IconButton>
                    <Typography mb={2}>
                        Please complete the information below to create an account for a new Employee account.
                    </Typography>
                    <Tabs
                        value={0}
                        sx={{ bgcolor: 'transparent' }}>
                        <TabList
                            //#region Tab Theme
                            sticky="top"
                            variant="plain"
                            sx={tabTheme}
                        //#endregion
                        >
                            <Tab value={0} key={0}>
                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        sm: "block"
                                    }
                                }}>New Account</Box>
                            </Tab>
                        </TabList>

                        <Box
                            //#region Container Theme
                            sx={{
                                height: 0,
                                position: 'sticky',
                                top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                                zIndex: 1,
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'relative',
                                    zIndex: 1,
                                    height: 'var(--_shadow-height)',
                                },
                            }}
                        //#endregion
                        >
                            <TabPanel
                                sx={{
                                    padding: 0
                                }}
                                key={0}
                                value={0}>
                                <Box
                                    //#region Page Layout
                                    sx={{
                                        pt: 3,
                                        pb: 10,
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            xs: '100%',
                                            sm: '100%'
                                        },
                                        columnGap: { xs: 2, sm: 3, md: 4 },
                                        rowGap: { xs: 2, sm: 1 },
                                        '& > hr': {
                                            gridColumn: '1/-1',
                                        },
                                    }}
                                //#endregion
                                >
                                    <Box sx={{ mt: 4 }}>
                                        <Typography sx={{ mb: 2 }} color='warning' fontSize={"lg"} startDecorator={<NearbyError />}>If you are hiring for the US and Canada, Oracle will now create and manage staff accounts. If you require assistance please reach out to your HR team.</Typography>
                                        {
                                            rootUser ?
                                                <>
                                                    <FormSection title="Personal" formItems={profileContent?.personal} />
                                                    <FormSection title="Contact" formItems={profileContent?.contact} />
                                                    <FormSection title="Work" formItems={profileContent?.work} />
                                                    <FormSection title="Finance" formItems={profileContent?.finance} />
                                                </> :
                                                <>
                                                    <FormSection title="Personal" formItems={baseCreateUser?.personal} />
                                                    <FormSection title="Contact" formItems={baseCreateUser?.contact} />
                                                    <FormSection title="Work" formItems={baseCreateUser?.work} />
                                                    <FormSection title="Finance" formItems={baseCreateUser?.finance} />
                                                </>
                                        }


                                    </Box>
                                    <Box
                                        id='bottom-buttons'
                                        sx={{
                                            gridColumn: '1/-1',
                                            justifyContent: 'flex-end',
                                            display: 'flex',
                                            gap: 1,
                                            paddingTop: 1
                                        }}
                                    >
                                        <Button
                                            // disabled={formSubmitted}
                                            type="submit"
                                            sx={{
                                                borderRadius: "0px",
                                            }} size="sm" >Submit</Button>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </Box>
                    </Tabs>

                </form >
                {(formSubmitted && openModal) && returnModal()}
            </Box > :
            <Box
                sx={{
                    flex: 1,
                    pl: 2,
                    pr: 2,
                    pt: 0,
                    maxWidth: 1200,
                    width: '100%',
                    mx: 'auto',
                }}>
                <Header icon={<PersonAdd />} title="Create a new Account" />
                <IconButton
                    sx={{
                        display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                        },
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pt: 0.5,
                        m: 2,
                        zIndex: 5
                    }}
                    onClick={() => toggleSidebar()}
                    color="neutral"

                >
                    <MoreVert sx={{ color: "white" }} />
                </IconButton>
                <UnauthorisedCreate />
            </Box>
    )
}